<template>
  <div class="detail">
    <div class="bread">首页 / 销售订单管理 / 发货单</div>
    <div class="detail-box">
      <p class="detail-title">订单信息</p>
      <div class="detail-info ">
        <div class="mgbt"><span>订单编号：</span>{{myData.id}}</div>
        <div class="info-time mgbt">
          <div><span>订单时间：</span>{{moment(myData.createTime).format('YYYY-MM-DD HH:mm:ss')}}</div>
          <!-- <div><span>接单时间：</span>{{myData.okTime}}</div> -->
        </div>
        <!-- <div class="info-foot mgbt">
          <div><span>公安处备案号：</span>无</div>
        </div> -->
      </div>
    </div>
    <div class="detail-box">
      <p class="detail-title">财务备注</p>
      <div class="detail-info ">
        <div class="info-time mgbt">
          <div><span>是否开票：</span>已开票</div>
          <div><span>是否付款：</span>已付款</div>
        </div>
        <div class="info-time mgbt">
          <div><span>开票信息备注：</span>{{myData.invoiceRemark}}</div>
        </div>
      </div>
    </div>
    <div class="detail-box">
      <p class="detail-title">收货人信息</p>
      <div class="detail-info ">
        <div class="info-time mgbt">
          <div><span>采购人：</span>{{myData.shopUserName}}</div>
          <div><span>收货人：</span>{{myData.consigneeName}}</div>
        </div>
        <div class="info-time mgbt">
          <div><span>收货地址：</span>{{myData.consigneeAddress}}</div>
          <div><span>手机号码：</span>{{myData.consigneePhone}}</div>
        </div>
      </div>
    </div>
    <div class="detail-list">
      <p class="detail-title">商品清单</p>
      <div class="list-box">
        <div class="list">
          <div class="list-head">
            <div  class="name table-head">商铺名称</div>
            <div class="list-name">
              <div class="mglt table-head">品牌</div>
              <!-- <div class="mglt table-head">型号</div> -->
              <!-- <div class="mglt table-head">规格</div> -->
              <!-- <div class="mglt table-head">生产厂家</div> -->
              <div class="mglt table-head">数量</div>
              <div class="mglt table-head">单价</div>
            </div>
          </div>
        </div>
        <div class="list" style="margin-top:10px" v-for="(item, index) in myData.products" :key="index">
          <div class="list-head">
            <div  class="name"><router-link to="">{{item.name}}</router-link></div>
            <div class="list-name">
              <div class="mglt">{{item.brandName}}</div>
              <!-- <div class="mglt">XP</div> -->
              <!-- <div class="mglt">电脑</div> -->
              <!-- <div class="mglt">联想</div> -->
              <div class="mglt">{{item.num}}</div>
              <div class="mglt price"><span>￥{{item.unitPrice}}</span></div>
            </div>
          </div>
        </div>
        <div class="list" style="margin-top:10px">
          <div class="list-price price">
            订单总金额：<span>￥{{allPirce}}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="detail-box" style="margin:0;">
      <p class="detail-title">物流信息</p>
      <div class="detail-logistics">
        <div class="logistics">
          <div class="logistics-head">备注</div>
          <div class="logistics-body">
            <div class="logistics-input">
              <span style="color:red;">* </span><label for="">发票说明：</label>
              <template>
                <a-textarea placeholder="在此填写详细的具体信息，如姓名和电话" v-model="info" :rows="4"/>
              </template>
            </div>
          </div>
        </div>
        <a-button size="large" class="my-btn" @click="handleClikInvoice" >确认</a-button>
      </div>
    </div> -->
  </div>
</template>

<script>
import { getOrderDetail, invoiceInfo } from '@/serve/order'
import moment from 'moment'
export default {
  name: 'orderDetail',
  data () {
    return {
      id: this.$route.params.id,
      orderInfo: {},
      info: '',
      myData: {},
      allPirce: 0 // 总价
    }
  },
  methods: {
    moment,
    init () {
      getOrderDetail(this.id).then(res => {
        this.myData = res
        res.products.forEach(item => {
          this.allPirce += item.num * item.unitPrice
        })
      })
    },
    handleClikInvoice () {
      invoiceInfo(this.id, { remark: this.info }).then(res => {
        this.$message.success('操作成功')
        // location.reload()
      }).catch(error => {
        this.$message.error('操作失败,' + error.data.message)
        // location.reload()
      })
    }
  },
  created () {
    this.init() // 初始化数据
  }
}
</script>

<style lang="less" scoped>
.detail{
  .bread{
    font-size: 16px;
    color: black;
    font-weight: 600;
  }
  .detail-box{
    margin:10px 0 0 0;
    border-top: 1px rgb(223,224,224) solid;
    .detail-title{
      color: rgb(146,166,187);
      font-size: 16px;
      background-color: rgb(239,240,240);
      height: 32px;
      line-height: 30px;
      padding: 0 0 0 10px
    }
    .detail-info{
      padding-left: 20px;
      font-size: 12px;
      color: black;
      .info-time{
        width: 70%;
        display: flex;
        justify-content: space-between;
      }
    }
    .detail-logistics{
      .logistics{
        width: 60%;
        margin: auto;
        .logistics-head{
          background-color: rgb(27,88,146);
          color: rgb(250,245,241);
          width: 17%;
          text-align: center;
          height: 30px;
          line-height: 30px;
        }
        .logistics-body{
          border: 1px solid rgb(86,131,174);
          border-top: 2px solid black;
          padding: 20px;
        }
      }
    }
  }
  .detail-list{
    margin:10px 0 0 0;
    border-top: 1px rgb(223,224,224) solid;
    .detail-title{
      color: rgb(146,166,187);
      font-size: 16px;
      background-color: rgb(239,240,240);
      height: 32px;
      line-height: 30px;
      padding: 0 0 0 10px;
      margin:0;
    }
    .list-box{
      border: 1px solid rgb(230,230,230);
      .list{
        padding-left: 20px;
        border-top: 1px solid rgb(230,230,230);
        .list-head {
          display: flex;
          .name{
            flex:0.3;
          }
          .list-name {
            justify-content: space-between;
            display: flex;
            flex:1;
            div{
              width: 80px;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
.mgbt{
  margin-bottom: 10px;
  span{
    font-size: 14px;
    font-weight: 600;
    // color: red;
  }
}
.table-head{
  // margin-left: 70px;
  font-size: 14px;
  font-weight: 600;
}
.list-price {
  display: flex;
  justify-content: flex-end;
  padding-right: 19px;
  height: 27px;
  line-height: 27px;
}
.price{
  span{
    color: red;
  }
}
.logistics-input {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 70%;
  label {
    width: 90px;
  }
}
.my-btn{
  position: relative;
  left: 43%;
  margin-top: 10px;
  width: 150px;
}
</style>
